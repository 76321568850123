import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { DefaultSeo } from 'next-seo';
import * as Sentry from '@sentry/node';

import config from 'config';
import { GlobalProvider } from 'context/Global';
import GoogleAnalytics from 'components/GoogleAnalytics';
import 'styles/style.css';

fontAwesomeConfig.autoAddCss = false;

// Sentry error tracking
Sentry.init(config('/sentry'));

class MyApp extends App {
  // Polyfill some DOMElement methods for IE11
  componentDidMount() {
    // By default, Next doesn't scroll to the top of the
    // page on manual Router interactions like .push(),
    // .replace(), and .back(), so we force it here
    Router.events.on('routeChangeComplete', () => window.scrollTo(0, 0));
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <GlobalProvider>
        <>
          <Head>
            <meta charSet="utf-8" key="charset" />
            <meta
              key="viewport"
              name="viewport"
              content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
            />
            <link rel="shortcut icon" href="/favicon.png" />
          </Head>
          {config('/env') === 'production' && <GoogleAnalytics />}
          <DefaultSeo
            title="Home of the Hot Rock"
            titleTemplate="%s | Flinchy's"
            openGraph={{
              type: 'website',
              url: 'https://flinchys.com',
              site_name: "Flinchy's",
              images: [
                {
                  url:
                    'https://res.cloudinary.com/globobeet/image/upload/w_800,h_600,c_fill,g_center/v1596658988/flinchys/opengraph.jpg',
                },
              ],
            }}
          />
          <Component {...pageProps} />
          <div className="fixed z-50 bottom-0 inset-x-0 flex justify-center lg:justify-end p-8 pointer-events-none">
            <div id="snackbars" className="w-full max-w-xl -mt-1" />
          </div>
        </>
      </GlobalProvider>
    );
  }
}

export default MyApp;

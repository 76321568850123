import React from 'react';
import { useRouter } from 'next/router';
import useDidMount from '@rooks/use-did-mount';
import ReactGA from 'react-ga';

import config from 'config';

const GoogleAnalytics = () => {
  const router = useRouter();
  const [initialized, setInitialized] = React.useState(false);

  useDidMount(() => {
    ReactGA.initialize(config('/google/analyticsTrackingId'));
    setInitialized(true);
  });

  React.useEffect(() => {
    if (initialized) ReactGA.pageview(router.asPath);
  }, [initialized, router.asPath]);

  return null;
};

export default GoogleAnalytics;
